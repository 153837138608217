import { memo } from "react";
import { Container, Row } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import { NavLink } from "components/NavLink";
// import { ImageAsyn } from "components/ImageAsyc";
// import Image from "assets/image/servicios/370x370_img8.jpg";
import servicio_hombres1 from "assets/image/servicios/servicio_hombres1.jpg";
import servicios_spa1 from "assets/image/servicios/servicios_spa1.jpg";
import servicios_depilacion_rostro1 from "assets/image/servicios/servicios_depilacion_rostro1.jpg";
import servicios_depilacion_cuerpo1 from "assets/image/servicios/servicios_depilacion_cuerpo1.jpg";
import servicios_cortes1 from "assets/image/servicios/servicios_cortes1.jpg";
import servicios_maquillaje1 from "assets/image/servicios/servicios_maquillaje1.jpg";
import servicios_alisado_ondulado1 from "assets/image/servicios/servicios_alisado_ondulado1.jpg";
import servicios_cambio_look1 from "assets/image/servicios/servicios_cambio_look1.jpg";
import servicios_cuidado_manos1 from "assets/image/servicios/servicios_cuidado_manos1.jpg";
import servicios_tratamientos_reductores1 from "assets/image/servicios/servicios_tratamientos_reductores1.jpg";
import servicios_tratamientos_capilares1 from "assets/image/servicios/servicios_tratamientos_capilares1.jpg";
import servicios_coloracion1 from "assets/image/servicios/servicios_coloracion1.jpg";
import servicios_novias_quinceanios1 from "assets/image/servicios/servicios_novias_quinceanios1.jpg";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
  1400: { items: 4 },
};

const items = [
  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicio_hombres1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Servicios para Hombres</h5>
    </div>
    <NavLink className="item_overlay" to="/web/servicios/view/s_hombres">
      <div className="text_holder">
        <p>Servicios para Hombres</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_spa1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Servicios de Spa</h5>
    </div>
    <NavLink className="item_overlay" to="/web/servicios/view/s_spa">
      <div className="text_holder">
        <p>Servicios de Spa</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_depilacion_rostro1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Depilaciones</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_depilacion_rostro"
    >
      <div className="text_holder">
        <p>Depilaciones</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_depilacion_cuerpo1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Depilaciones de Cuerpo Entero</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_depilacion_cuerpo"
    >
      {" "}
      <div className="text_holder">
        <p>Depilaciones de Cuerpo Entero</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_cortes1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Cortes</h5>
    </div>
    <NavLink className="item_overlay" to="/web/servicios/view/s_cortes">
      {" "}
      <div className="text_holder">
        <p>Cortes</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_maquillaje1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Maquillaje</h5>
    </div>
    <NavLink className="item_overlay" to="/web/servicios/view/s_maquillaje">
      {" "}
      <div className="text_holder">
        <p>Maquillaje</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_alisado_ondulado1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Alizado y Ondulacion</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_alizado_ondulacion"
    >
      <div className="text_holder">
        <p>Alizado y Ondulacion</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_cambio_look1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Cambio de Look</h5>
    </div>
    <NavLink className="item_overlay" to="/web/servicios/view/s_cambio_look">
      <div className="text_holder">
        <p>Cambio de Look</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_cuidado_manos1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Cuidado de Manos</h5>
    </div>
    <NavLink className="item_overlay" to="/web/servicios/view/s_cuidado_manos">
      <div className="text_holder">
        <p>Cuidado de Manos</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_tratamientos_capilares1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Tratamiento Capilar</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_tratamiento_capilar"
    >
      <div className="text_holder">
        <p>Tratamiento Capilar</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_coloracion1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Trabajos en Coloracion</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_trabajos_coloracion"
    >
      <div className="text_holder">
        <p>Trabajos en Coloracion</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_tratamientos_reductores1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Tratamientos Reductores</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_tratamientos_reductores"
    >
      <div className="text_holder">
        <p>Tratamientos Reductores</p>
      </div>
    </NavLink>
  </div>,

  <div className="mad_item_hover px-3">
    <figure>
      <img src={servicios_novias_quinceanios1} alt="" />
    </figure>
    <div className="mad_item_desc with_bg_img">
      <h5>Novias y 15 años</h5>
    </div>
    <NavLink
      className="item_overlay"
      to="/web/servicios/view/s_novias_quince_anios"
    >
      {" "}
      <div className="text_holder">
        <p>Novias y 15 años</p>
      </div>
    </NavLink>
  </div>,
];

const Carousel = () => (
  <AliceCarousel
    mouseTracking
    infinite
    autoPlay
    autoPlayInterval={1000}
    animationDuration={1000}
    animationType="fadeout"
    disableDotsControls
    disableButtonsControls
    items={items}
    responsive={responsive}
    controlsStrategy="alternate"
  />
);

const ServiciosSection = () => {
  return (
    <div className="paralax_image_bg2-1">
      <svg
        className="separator_type_6_path top"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
        version="1.1"
        // xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0 L30 100 L100 0 L100 100 L0 100 Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="-1"
        ></path>
        <path
          d="M0 0 L0 0 L50 100 L100 0 L100 0 Z"
          fill="rgba(255,255,255,0.01)"
        ></path>
      </svg>

      <Container>
        <Row>
          <Colxx sm="12">
            <div className="mad_section_container">
              <h3 className="mad_title_style1">Servicios</h3>
              <h2 className="mad_separator">Nuestros Servicios</h2>
            </div>

            <div className="carousel_type_2">
              <div className="">
                <Carousel />
              </div>

              {/* <div className="align_center">
                                <a
                                    href="https://dominio.com"
                                    className="mad_button style2"
                                >
                                    Todos nuestros servicios
                                </a>
                            </div> */}
            </div>
          </Colxx>
        </Row>
      </Container>

      <svg
        className="separator_type_6_path"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
        version="1.1"
        // xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0 L70 100 L100 0 L100 100 L0 100 Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="-1"
        ></path>
        <path
          d="M0 -1 L0 0 L50 100 L100 0 L100 -1 Z"
          fill="rgba(255,255,255,0.01)"
        ></path>
      </svg>
    </div>
  );
};

export default memo(ServiciosSection);
